<template>
    <div class="job-intro" v-if="profile">
        <div class="title" v-html="$translate('JOB_INTRODUCE')" />
        <div class="intro" v-html="jobIntro" />
    </div>
</template>
<script>
export default {
    name: 'JobIntro',
    props: ['profile'],
    computed: {
        jobIntro() {
            return (this.profile.job_description || '').trim() || '-'
        },
    },
}
</script>

<style lang="scss" scoped>
.job-intro {
    margin-top: 12px;
    .title {
        font-size: 12px;
        font-family: NotoSans-Medium;
        font-weight: 500;
        color: $black;
        margin-bottom: 4px;
    }

    .intro {
        font-size: 12px;
        font-family: NotoSans-Regular;
        color: $grey-09;
        line-height: 1.42;
    }
}
</style>

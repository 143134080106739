<template>
    <div class="modal-accepted-history">
        <div class="header flex-row flex-between">
            <div class="titles">
                <span class="title" v-html="$translate('ACCEPTED_HISTORY_TITLE')"></span>
                <span class="desc" v-html="$translate('ACCEPTED_HISTORY_SUBTITLE')"></span>
            </div>
            <i @click="onClose" class="zmdi zmdi-close" />
        </div>
        <div class="body">
            <div class="info-photo-wrapper">
                <span class="info">
                    <!-- <span class="name f-medium f-16">{{ options.user.name }} </span> -->
                    <!-- ({{ options.user.profile.birthday | asAge }}세, {{ options.user.profile.height }}cm) -->
                </span>
                <div class="user-photo-wrapper">
                    <div
                        class="photo-rating"
                        :key="idx"
                        v-for="(history, idx) in histories || []"
                        @click="onClickProfile(history)"
                    >
                        <img
                            class="photo"
                            :src="considerBlankProfile(history.photo)"
                            @error="$event.target.src = $blankProfile"
                        />
                        <div v-if="history.rating" class="rating" v-html="history.rating" />
                    </div>
                </div>
            </div>

            <div class="introducing-user bg-white flex" v-if="user">
                <div class="open-chat" @click="onClickOpenChat">
                    <div class="text" v-html="'대화방 이동'" />
                </div>
                <div class="basic-profile section flex-wrap">
                    <div class="flex-row items-start">
                        <UserProfile v-if="user.profile" class="flex-fill" :user="user" />
                    </div>
                </div>
                <div class="ver-scroll p-l-16 p-r-16">
                    <div class="user-photos-selector">
                        <div class="grid-col" v-if="images">
                            <div
                                class="img-profile animate-size p-relative cursor-pointer"
                                :key="img.id"
                                v-lazy:background-image="img.photo_url"
                                v-for="img in images"
                            ></div>
                        </div>
                    </div>
                    <div v-if="(user.profile && user.profile.intro) || user.style" class="additional flex">
                        <div :style="{ display: 'flex' }">
                            <div
                                class="m-r-24 flex-fill"
                                v-if="user.profile && (user.profile.intro || user.profile.job_description)"
                            >
                                <UserIntro v-if="user.profile && user.profile.intro" :profile="user.profile" />
                                <JobIntro v-if="user.profile && user.profile.job_description" :profile="user.profile" />
                            </div>
                            <UserStyles
                                v-if="user.style"
                                class="flex-fill"
                                :class="{
                                    oneline:
                                        user.style &&
                                        user.profile &&
                                        !user.profile.intro &&
                                        !user.profile.job_description,
                                }"
                                :user="user"
                            />
                        </div>
                        <div class="hr" />
                        <LikeTags v-if="user && user.keywords && user.keywords.length !== 0" :user="user" />
                        <div class="hr" />
                        <!-- <DatingStyles
                            v-if="user && user.dating_preferences && user.dating_preferences.length !== 0"
                            :user="user"
                        /> -->
                        <div v-if="user.profile && user.profile.intro && user.style" class="flex-wrap m-8" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import userService from '@/services/user'
import UserIntro from '@/components/common/UserIntro'
import JobIntro from '@/components/common/JobIntro'
import UserStyles from '@/components/common/UserStyles'
import LikeTags from '@/components/common/LikeTags'
import suggestService from '@/services/suggest'

export default {
    name: 'ModalAcceptedHistory',
    props: {
        options: {
            type: Object,
            required: true,
        },
    },
    components: {
        UserIntro,
        JobIntro,
        UserStyles,
        LikeTags,
    },
    watch: {
        user() {
            this.initImages()
        },
    },
    data: () => ({
        histories: [],
        images: [],
        currentUser: null,
    }),
    computed: {
        chat() {
            return this.$store.getters.chat || {}
        },
        user() {
            return this.chat.$$premiumAcceptedUser
        },
    },
    mounted() {
        this.init()
        this.initImages()
    },
    methods: {
        async init() {
            this.histories = await userService.acceptedHistory(this.options.user_id)
            this.currentUser = this.chat.user
        },
        initImages() {
            this.images = ((this.user || {}).photos || []).map(p => ({
                photo_url: p.url,
                checked: false,
            }))
        },
        considerBlankProfile(item) {
            return item ? item : this.$blankProfile
        },
        async onClickProfile(history) {
            try {
                await this.$store.dispatch('loadPremiumAcceptedUser', history.id)
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
        onClose() {
            this.$store.dispatch('closeIntroducingUser')
            this.$emit('close')
        },
        async onClickOpenChat() {
            const chat = this.$getChat(this.user.id)

            if (chat && !chat.expire_at) {
                this.$store.dispatch('loadChat', {
                    userId: this.user.id,
                    introducingUserId: this.user.id,
                })
                // return
                // this.openChat()
                // else if 거절당한 이력이 있는 경우?
            } else {
                this.$loading(true)
                try {
                    const data = await suggestService.makeChatRoom({
                        user_id: this.user.id,
                        source_id: this.currentUser.id,
                    })
                    this.$toast.success(data.msg)

                    this.$store.dispatch('loadChat', {
                        userId: this.user.id,
                    })
                    // const suggests = await suggestService.all(this.user.id, this.source_id)

                    // const suggest = suggests[0]

                    // const data = await suggestService.acceptRequest(suggest.id)
                    // // 항상 서버에 요청하고 서버에서 처리
                    // this.$toast.success(data.msg)

                    // this.$store.dispatch('loadChat', {
                    //     userId: this.user.id,
                    //     introducingUserId: this.user.id,
                    // })
                } catch (e) {
                    this.$toast.error(e.data)
                }
                this.$loading(false)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
$introducing-basic-profile-height: 216px;
$sending-picture-size: calc((#{$panel-width-introducing-user} - 16px * 2 - 8px) / 2);

.modal-accepted-history {
    $user-photo-size: 160px;
    $sample-photo-size: 88px;
    $padding-vertical: 24px;
    $padding-horizontal: 20px;
    $gap: 8px;
    $button-wrapper-height: 138px;

    width: 90vw;
    height: 90vh;
    padding: 0;
    @include flex;
    overflow-y: auto;

    .header {
        padding: $padding-vertical $padding-horizontal;
        border-bottom: solid 1px $grey-02;
        line-height: normal;
        // display: flex;
        .titles {
            @include flex;
        }
    }

    .title {
        font-size: 18px;
        color: black;
        margin-bottom: $gap;
        @include f-bold;
    }

    .desc {
        color: $grey-08;
    }

    .body {
        padding: 0 $padding-horizontal;
        flex-grow: 1;
        display: flex;

        .introducing-user {
            $grid-gap: 8px;
            position: relative;

            .open-chat {
                border-radius: 8px;
                background-color: $purple-primary;
                width: fit-content;
                height: 24px;
                margin-left: 16px;
                margin-top: 12px;
                margin-bottom: -8px;
                padding: 3px 10px 4px 8px;
                color: white;
                @include f-bold;
                font-size: 11px;
            }

            .functions {
                .button {
                    width: 40px;
                    height: 40px;
                    background: $grey-02;
                }
            }

            .basic-profile {
                padding: 4px 16px 0;
            }

            .buttons {
                border-top: 1px solid $grey-02;
                padding: 16px;

                .divider {
                    color: $grey-03;
                    margin: 4px;
                    font-size: 20px;
                }

                .send-all {
                    min-width: $sending-picture-size;
                }
            }

            .section {
                padding: 20px 16px 24px;
            }

            .additional {
                margin-top: 24px;
                padding-top: 24px;
                padding-bottom: 40px;
                border-top: 1px solid $grey-02;
            }
        }

        .info {
            line-height: normal;
            color: black;
            font-size: 13px;
            @include f-regular;
        }

        .user-photo-wrapper {
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            row-gap: $gap;
            column-gap: $gap;
            margin-top: 16px;
            margin-bottom: $padding-vertical;

            .photo-rating {
                .photo {
                    // width: $user-photo-size;
                    // height: $user-photo-size;
                    width: 15vh;
                    height: 15vh;
                    border-radius: 8px;
                    border: solid 1px $grey-02;
                }
                .rating {
                    margin-top: -3vh;
                    margin-left: 2vh;
                    width: 3vh;
                    height: 2vh;
                    opacity: 0.6;
                    border-radius: 6px;
                    background-color: black;
                    text-align: center;
                    font-size: 11px;
                    font-family: NotoSans-Bold;
                    color: $yellow-intensive;

                    // position: absolute;
                }
            }
        }

        .button-wrapper {
            margin-bottom: $gap;
            @include flex;

            button {
                height: 36px;
                border: solid 1px white;
                color: white;
                font-size: 14px;
                font-weight: 500;
                @include f-medium;

                &:first-child {
                    border-top-left-radius: 8px;
                    border-bottom-left-radius: 8px;
                }

                &:last-child {
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                }
            }

            .desc {
                font-size: 12px;
                line-height: 1.5;
                color: $pink-deep;
                @include f-medium;
            }
        }
    }

    @media (max-width: $screen-xs) {
        .title {
            font-size: 24px;
        }

        .desc {
            font-size: 13px;
        }

        .body {
            position: relative;

            .info-photo-wrapper {
                padding-bottom: calc(#{$button-wrapper-height} - #{$padding-vertical});
            }

            .user-photo-wrapper {
                grid-template-columns: repeat(2, 1fr);
            }
            .photo {
                $screen-xs-photo-size: calc((100vw - 2 * #{$padding-horizontal} - 2 * #{$gap}) / 2);
                width: $screen-xs-photo-size;
                height: $screen-xs-photo-size;
            }

            .button-wrapper {
                border: 1px solid $grey-02;
                position: fixed;
                left: 0;
                bottom: 0;
                padding: 16px 20px;
                width: 100%;
                background: white;
                margin-bottom: 0;
            }
        }
    }
}
</style>
